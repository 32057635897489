<template>
    <ValidationObserver ref="observe">
        <b-form>
            <ek-dialog 
                ref="addDialog" 
                title="إضافة إعلان" 
                size="md" 
                placeholder="ابحث عن اعلان محدد"
                btnText="اضافة إعلان" 
                @ok="submit()" 
                @close="reset"
                @search="setSearch">
                <template #body>
                    <b-form-group label="نوع الإعلان" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            :options="advertOptions.map((el) => ({ value: el.id , text: el.name}))"
                            :aria-describedby="ariaDescribedby"
                            v-model="advertDto.mediaType"
                            name="radio-options"
                        ></b-form-radio-group>
                    </b-form-group>
                    <ek-input-text 
                        :rules="[{ type: 'required', message: 'عنوان الإعلان مطلوب' }]"
                        name="name"
                        placeholder="أدخل عنوان الاعلان " 
                        label=" عنوان الاعلان" 
                        v-model="advertDto.titl"
                    ></ek-input-text>

                    <ek-date-picker 
                        :rules="[{ type: 'required', message: 'يرجى ادخال مدة الإعلان ' },]"
                        name="date" 
                        placeholder="ادخل مدة الاعلان" 
                        label=" مدة الإعلان" 
                        range
                        v-model="advertDto.adsDatesList" 
                        @input="updateAdsDate($event)"
                    ></ek-date-picker>

                    <ek-input-text 
                        :rules="[{ type: 'required', message: 'نص الاعلان مطلوب' }]" 
                        name="text" 
                        placeholder="ادخل نص الاعلان" 
                        label="النص " 
                        v-model="advertDto.content">
                    </ek-input-text>

                    <ek-input-image
                        ref="inputFile" 
                        v-if="advertDto.mediaType == 1" 
                        @input="updateAdVideo">
                        <h5> {{  "  غلاف الفيديو  " }} </h5>
                    </ek-input-image>

                    <ek-input-image 
                        ref="imgFile"
                        v-if="advertDto.mediaType < 3" 
                        @input="updateAdsImg">
                        <h5> {{ advertDto.mediaType == 1 ? "ملف الفيديو" : "اسحب الملف او انقر للتحميل" }} </h5>
                    </ek-input-image>
                    
                    
                </template>
            </ek-dialog>
        </b-form>
    </ValidationObserver>
</template>

<script>

import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapMutations } from "vuex";
import { BToast } from "bootstrap-vue";

export default {
    components: {
        ValidationObserver
    },
    computed: {
        ...mapState({
            advertDto: state => state.adverts.advertDto,
            advertOptions: state => state.adverts.advertOptions
        }),
    },
    methods: {
        ...mapActions(["addAds"]),
        ...mapMutations(['Update_Ads_Dto_Date']),
        submit() {
            this.$refs.observe.validate().then(success => {
                if (success && this.advertDto.file) {
                    this.addAds(this.advertDto);
                    this.$refs.addDialog.close();
                    this.$refs.observe.reset();
                }
                else{
                    const bToast = new BToast();
                    bToast.$bvToast.toast(
                        'يرجى اختيار ملف',
                            {
                                title: "الملف  مطلوب",
                                variant: "danger",
                                toaster: "b-toaster-bottom-right",
                                solid: true,
                                autoHideDelay: 2000,
                                appendToast: true
                            }
                        ); 
                }
            });
        },
        setSearch(e) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["titl", "content"],
                query: e.toLowerCase(),
            });
        },
        reset() {
            this.$refs["observe"].reset();
            Object.assign(this.advertDto, {
                titl: "",
                content: "",
                startDate: "",
                endDate: "",
                adsDatesList:"",
            });
            this.$refs.imgFile.reset();
            this.$refs.inputFile.reset();
        },
        updateAdsDate($event) {
            this.Update_Ads_Dto_Date($event);
        },
        updateAdsImg({file}) {
            this.advertDto.file = file;
        },
        updateAdVideo({file}) {
            this.advertDto.thumbFile = file
        }
    },
    beforeDestroy() {
        this.$store.commit("Reset_Ads_Dto");
    }
};
</script>
